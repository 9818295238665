<template>
    <div class="education-wrapper">
        <div class="education-header">
            <el-breadcrumb separator-class="el-icon-arrow-right">
                <el-breadcrumb-item :to="{ path: '/admin/index' }">教务管理</el-breadcrumb-item>
                <el-breadcrumb-item>评委</el-breadcrumb-item>
            </el-breadcrumb>
        </div>
        <el-table :data="teacherList" border style="width: 100%; margin-top: 20px; flex: 1" height="1%" size="medium"
                  :header-cell-style="{fontWeight: 'normal', height: '60px', color: '#13131B', background: '#F2F3FA'}"
                  :cell-style="{fontSize: '14px',color: '#343441'}">
            <el-table-column prop="name" align="center" label="评委名称"></el-table-column>
            <el-table-column prop="username" align="center" label="账号"></el-table-column>
            <el-table-column align="center" label="头像">
                <template slot-scope="scope">
                    <div class="school-logo">
                        <img :src="scope.row.headimgurl ? scope.row.headimgurl : require('../../../assets/images/user_img.png')" alt="">
                    </div>
                </template>
            </el-table-column>
            <el-table-column prop="expand.college_name" align="center" label="学校"></el-table-column>
        </el-table>
        <el-pagination class="pager-center" style="margin-top: 10px;"
                       :current-page="listPages.currentPageNum"
                       :page-size="listPages.eachPageNum"
                       :total="listPages.total"
                       layout="prev, pager, next, jumper"
                       @current-change="pagesCurrentChange">
        </el-pagination>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                teacherList: [],
                listPages: {
                    currentPageNum: 1,
                    eachPageNum: 10,
                    total: 0,
                }
            }
        },
        mounted() {
            this.getTeacherList()
        },
        methods: {
            // 获取教师列表
            getTeacherList() {
                let params = {
                    paging: 1,
                    page: this.listPages.currentPageNum,
                    pageSize: this.listPages.eachPageNum
                }
                this.$http.axiosGetBy(this.$api.judgesAdminList, params, (res) => {
                    if (res.code === 200) {
                        this.teacherList = res.data.data
                        this.listPages.total = res.data.total
                        if (this.listPages.total !== 0 && this.teacherList.length === 0) {
                            this.listPages.currentPageNum--;
                            this.getTeacherList();
                        }
                    } else {
                        this.$message.warning(res.msg)
                    }
                })
            },
            // 切换分页
            pagesCurrentChange(val) {
                this.listPages.currentPageNum = val
                this.getTeacherList()
            }
        }
    }
</script>

<style scoped lang="scss">
    .education-wrapper {
        padding: 30px;
        height: 100%;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        .education-header {
            display: flex;
            align-items: center;
            justify-content: space-between;
            .el-breadcrumb {
                line-height: 40px;
            }
        }
    }
    .el-link + .el-link {
        margin-left: 10px;
    }
    .school-logo {
        width: 40px;
        height: 40px;
        overflow: hidden;
        border-radius: 50%;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        img {
            max-width: 100%;
            max-height: 100%;
        }
    }
</style>