<template>
    <el-tabs v-model="educationTab" type="card" class="education-content tabs-full" @tab-click="toggleEducationTab">
        <el-tab-pane label="学校管理" name="schoolManage">
            <SchoolManage ref="schoolManege" />
        </el-tab-pane>
        <el-tab-pane label="学校管理员" name="schoolAdministrator">
            <SchoolAdministrator ref="schoolAdmin" />
        </el-tab-pane>
        <el-tab-pane label="训练管理员" name="trainManage">
            <TrainManage ref="trainAdmin" />
        </el-tab-pane>
        <el-tab-pane label="评委" name="teacherManage">
            <TeacherManage ref="teacherManage" />
        </el-tab-pane>
    </el-tabs>
</template>

<script>
    import SchoolManage from '@/components/admin/educationManage/SchoolManage.vue'
    import SchoolAdministrator from '@/components/admin/educationManage/SchoolAdministrator.vue'
    import TrainManage from '@/components/admin/educationManage/TrainManage.vue'
    import TeacherManage from '@/components/admin/educationManage/TeacherManage.vue'

    export default {
        data() {
            return {
                educationTab: 'schoolManage'
            }
        },
        components: {
            SchoolManage,
            SchoolAdministrator,
            TrainManage,
            TeacherManage
        },
        methods: {
            toggleEducationTab(tab) {
                if (tab.name === 'schoolManage') { // 学校
                    this.$refs.schoolManege.getSchoolList()
                } else if (tab.name === 'schoolAdministrator') {  // 学校管理员
                    this.$refs.schoolAdmin.getSchoolAdminList()
                    this.$refs.schoolAdmin.getSchoolList()
                } else if (tab.name === 'trainManage') { // 训练管理员
                    this.$refs.trainAdmin.getTrainAdminList()
                    this.$refs.trainAdmin.getSchoolList()
                } else if (tab.name === 'teacherManage') { // 评委
                    this.$refs.teacherManage.getTeacherList()
                }
            }
        }
    }
</script>

<style scoped lang="scss">
    .education-content {
        height: 100%;
    }
</style>